import CardProductV2 from "@Components/CardProductV2";
import { Skeleton } from "@mui/material";
import Link from "@Components/Link";
import Slider from "react-slick";
import useCountdownTime from "hook/useCountdownTime";
import React, { memo, useMemo, useState } from "react";
import { Paginate } from "models/model";
import Product, { IProduct } from "models/product.model";
import { FlashSale } from "models/flashSale.model";
import Bookmark from "@Components/modal/Bookmark";

function FlashSaleSection({
   isMobile,
   paginateFlashSale,
   flashSale,
   onEndCountdownFlashSales,
}: {
   isMobile: boolean;
   paginateFlashSale: Paginate<Product, IProduct>;
   flashSale: FlashSale;
   onEndCountdownFlashSales: () => void;
}) {
   const [notification, setNotification] = useState<{ code: number; show: boolean }>();
   const { hours, minutes, seconds } = useCountdownTime(new Date(flashSale.info.startDateTime || 0), new Date(flashSale.info.endDateTime || 0), (time) => {
      onEndCountdownFlashSales();
   });
   const handleChangeNotification = (data: { code: number; show: boolean }) => {
      setNotification(data);
   };
   return (
      <section className="home-flash-sale">
         <div className="home-flash-sale__head ">
            <div className="home-flash-sale__head--left">
               {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                     d="M8 0C8.68355 4.10201 11.898 7.31645 16 8C11.898 8.68355 8.68355 11.898 8 16C7.31645 11.898 4.10201 8.68355 0 8C4.10201 7.31645 7.31645 4.10201 8 0Z"
                     fill="#FF6347"
                  />
               </svg> */}
               <img className="" src="/assets/img/home/flashsale-icon.png" alt="" />
               {/* <h2 className="title">Flash sale</h2> */}
               <div className="home-flash-sale__countdown">
                  <div className="hour">{hours.toString().padStart(2, "0")}</div>:<div className="minute">{minutes.toString().padStart(2, "0")}</div>:
                  <div className="second">{seconds.toString().padStart(2, "0")}</div>
               </div>
            </div>
            <Link href="/shop/flash-sale" className="view-all ">
               <span className="viewall-text">Xem tất cả </span>
               <span className="next-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                     <path d="M9 6L15 12L9 18" stroke="#FF6347" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
               </span>
            </Link>
         </div>
         <div className="home-flash-sale__content">
            {isMobile ? (
               <Slider className="d-block d-md-none" dots={false} speed={500} slidesToShow={2.5} slidesToScroll={2} infinite={false}>
                  {paginateFlashSale && paginateFlashSale?.docs.length > 0
                     ? paginateFlashSale?.docs.slice(0, 6).map((product) => {
                          return (
                             <div className="w-item" key={product._id}>
                                <CardProductV2
                                   showDes
                                   product={product}
                                   bookmark={product.__currentUserBookmark}
                                   onChangeNotification={(noti) => handleChangeNotification(noti)}
                                />
                             </div>
                          );
                       })
                     : [...Array(6)].map((e, i) => (
                          <div className="w-item" key={i}>
                             <Skeleton animation="wave" width="100%" height={170} />
                          </div>
                       ))}
               </Slider>
            ) : (
               <div className="d-none d-md-block">
                  <div className="row g-2">
                     {paginateFlashSale && paginateFlashSale?.docs?.slice(0, 6)?.length > 0
                        ? paginateFlashSale?.docs.slice(0, 6).map((product) => {
                             return (
                                <div className="col-2" key={product._id}>
                                   <CardProductV2
                                      showDes
                                      product={product}
                                      bookmark={product.__currentUserBookmark}
                                      fixedHeight
                                      onChangeNotification={(noti) => handleChangeNotification(noti)}
                                   />
                                </div>
                             );
                          })
                        : [...Array(6)].map((e, i) => (
                             <div className="col-2" key={i}>
                                <Skeleton animation="wave" width="100%" height={170} />
                             </div>
                          ))}
                  </div>
               </div>
            )}
         </div>
         <Bookmark show={notification?.show} notification={notification?.code} handleClose={() => handleChangeNotification({ code: 0, show: false })} />
      </section>
   );
}

export default React.memo(FlashSaleSection, (prevProps, nextProps) => prevProps.flashSale._id !== nextProps.flashSale._id);
