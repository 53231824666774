import React, { useEffect, useState } from "react";
import Link from "next/link";
import Slider from "react-slick";
import { Skeleton } from "@mui/material";

// Components
import { currency } from "../../../utils";
import { Paginate } from "../../../models/model";
import { ImageView } from "../../../components/image";
import Bookmark from "../../../components/modal/Bookmark";
import Product, { IProduct } from "../../../models/product.model";
// import BookmarkPost from "../../../components/bookmark/BookmarkPost";
import { EUserBookmarkTypes, IUserBookmark } from "../../../models/userBookmark.model";
import dynamic from "next/dynamic";
import CardProductV2 from "@Components/CardProductV2";
import classNames from "classnames";

const BookmarkPost = dynamic(() => import("../../../components/bookmark/BookmarkPost"), {
   ssr: false,
   loading: () => <div style={{ height: "270px" }} />,
});

interface IProps {
   className?: string;
   userBookmarks: IUserBookmark[];
   products?: {
      paginate: Paginate<Product, IProduct>;
      userBookmarks?: IUserBookmark[];
   };
   handleChangeUserBookmark?: (action: "create" | "delete", userBookmark: IUserBookmark) => void;
}

const settingsSlider = {
   dots: false,
   speed: 500,
   slidesToShow: 6,
   slidesToScroll: 1,
   loop: false,
   infinite: false,
   responsive: [
      {
         breakpoint: 576,
         settings: {
            slidesToShow: 2.5,
            slidesToScroll: 2,
         },
      },
   ],
};

export default function BestSeller({ ...props }: IProps) {
   const [userBookmarksState, setUserBookmarks] = useState<IUserBookmark[] | undefined>(props.userBookmarks);
   const [notification, setNotification] = useState<{ code: number; show: boolean }>();

   useEffect(() => {
      setUserBookmarks(props?.userBookmarks);
   }, [props?.userBookmarks]);

   const handleChangeNotification = (data: { code: number; show: boolean }) => {
      setNotification(data);
   };

   return (
      <section className={classNames("product-horizontal", props.className)}>
         {!props?.products ? (
            <div className="product-list">
               <div className="row gx-3">
                  {[...Array(4)].map((e, i) => (
                     <div className="col" key={i}>
                        <Skeleton animation="wave" width="100%" height={170} />
                     </div>
                  ))}
               </div>
            </div>
         ) : (
            <Slider {...settingsSlider} className="product-slider">
               {props?.products?.paginate?.docs?.map((product, i: number) => {
                  const bookmark = userBookmarksState?.find((bookmark) => bookmark?.info?.openId === product._id);
                  return (
                     <div className="slider-item" key={product._id}>
                        <CardProductV2
                           showDes
                           fixedHeight
                           product={product}
                           bookmark={bookmark}
                           onChangeNotification={(noti) => handleChangeNotification(noti)}
                        />
                     </div>
                  );
               })}
            </Slider>
         )}
         <Bookmark show={notification?.show} notification={notification?.code} handleClose={() => handleChangeNotification({ code: 0, show: false })} />
      </section>
   );
}
