import React from "react";
import dynamic from "next/dynamic";
import { NextSeoProps } from "next-seo";
import { GetServerSidePropsContext, InferGetServerSidePropsType } from "next";
import Product, { IProduct } from "../models/product.model";
import { _metadata } from "../utils/local-data";
import HomePage from "../models/homepage.model";
import Keyword, { EType, TListRoom } from "../models/keyword.model";
import Brand, { IBrand } from "../models/brand.model";

import ConstructionOverall from "@Modules/homev2/components/Construction";
import ECommerceOverall from "@Modules/homev2/components/ECommerce";
import ContentEndPage from "@Modules/homev2/components/ContentEndPage";
import { FlashSale, IFlashSale } from "models/flashSale.model";
import { GlobalComponentProps } from "models/types";
import Partner, { IPartner } from "models/partner.model";
import { sharedServerSideLogic } from "utils/sharedServerSideLogic";
import DialogAds from "@Components/DialogAds";
import { getDevices, getUserSecretIp } from "utils/SSR/index.handler";
import Banner, { EBannerDevice, EBannerSection, IBanner } from "@Models/banner.model";
import { logclient } from "utils/log";
import Menu from "@Models/menu.model";

import MainCategory from "@Modules/homev2/components/MainCategory";
import HornorBrand from "@Modules/homev2/components/HornorBrand";
import SpacetParner from "@Modules/homev2/components/SpacetParner";
import { connect } from "react-redux";
import ModalReceiveCoin from "@Components/modal/ModalReceiveCoin";
import { getMainCategorys } from "@Config/shop";
import { ModelError, Paginate } from "@Models/model";
import ActualWork, { IActualWork } from "@Models/actualWork.model";
import CoinAttention from "@Modules/homev2/components/CoinAttention";
import NewsBrand from "@Modules/homev2/components/NewsBrand";

const CommunityOverall = dynamic(() => import("@Modules/homev2/components/Community"), {
   ssr: false,
   loading: () => <div className="feed-height-init" />,
});
const Banners = dynamic(() => import("@Modules/homev2/components/Banners"), {
   ssr: true,
   loading: () => <div style={{ height: "400px" }} />,
});
//
interface IState {
   dialogReceiveCoin?: boolean;
}

interface IProps extends GlobalComponentProps, InferGetServerSidePropsType<typeof getServerSideProps> {
   auth: any;
}

export const URL_ACCEPT_BUTTON = ["/", "/thiet-ke-noi-that", "/thi-cong-noi-that", "/quy-trinh-ket-noi"];
class Home extends React.Component<IProps, IState> {
   constructor(props: IProps) {
      super(props);
      this.state = {};
   }
   componentDidMount(): void {
      window.history.scrollRestoration = "auto";
   }

   render() {
      logclient(this);
      const shopCategories = getMainCategorys(this.props.menu.levelTwo.shop);
      return (
         <>
            <div className="page-content" id="pageContent">
               <main className="home-pagev2">
                  <h1 className="d-none">{_metadata[this.props.router.pathname]?.title}</h1>
                  {this.props.banners && (
                     <div className="block_banner">
                        <div className="st-container position-relative">
                           <Banners
                              data={this.props.banners.filter((banner) => banner.section === EBannerSection.homepage)}
                              bannerRight={this.props.banners.filter((banner) => banner.section === EBannerSection.homepageright)}
                           />
                           <CoinAttention auth={this.props.auth} goToEarnCoin={() => this.setState({ dialogReceiveCoin: true })} />
                        </div>
                     </div>
                  )}
                  <MainCategory />
                  <HornorBrand />
                  <div className="main-content">
                     <div className="st-container">
                        <div className="row g-2 g-md-3">
                           {this.props.dataHomepage && (
                              <CommunityOverall {...this.props} dataHomepage={this.props.dataHomepage} keywordGroupsByType={this.props.keywordGroupsByType} />
                           )}
                           <ECommerceOverall
                              isMobile={this.props.isMobile}
                              shopCategories={shopCategories}
                              flashSaleWithProduct={this.props.flashSaleWithProduct}
                              products={this.props.products}
                              productsPropose={this.props.productsPropose}
                           />
                           {this.props.dataHomepage && (
                              <ConstructionOverall
                                 // {...this.props}
                                 dataHomepage={this.props.dataHomepage}
                                 keywordGroupsByType={this.props.keywordGroupsByType}
                              />
                           )}
                           <NewsBrand />
                           <SpacetParner />
                           <ContentEndPage
                              brands={this.props.brands}
                              menulv2={this.props.menu.levelTwo?.shop?.filter((it) => it.info.slug)}
                              partners={this.props.partners}
                           />
                        </div>
                     </div>
                  </div>
               </main>
            </div>
            <DialogAds />
            {/* <StickyRightButton /> */}
            {this.state.dialogReceiveCoin && (
               <ModalReceiveCoin
                  visible={this.state.dialogReceiveCoin}
                  onClose={async () => {
                     this.setState({ dialogReceiveCoin: false });
                  }}
               />
            )}
         </>
      );
   }
}

export default connect((state: any) => ({ auth: state.auth }))(Home);

export const getServerSideProps = async (ctx: GetServerSidePropsContext) => {
   const cookiesToken = ctx.req.cookies.userToken || "";
   const userIp = getUserSecretIp(ctx.req);
   // console.log("userIp", userIp);
   const { isMobile } = getDevices(ctx.req);

   let keywordGroupsByType: TListRoom | null = null;
   // let homePage: any = {};
   let actualWorks: Paginate<ActualWork, IActualWork> | null = null;
   let designLibraries: Paginate<ActualWork, IActualWork> | null = null;
   let products: Paginate<Product, IProduct> | null = null;
   let productsPropose: Paginate<Product, IProduct> | null = null;
   let brands: Paginate<Brand, IBrand> | null = null;
   let flashSaleWithProduct: Paginate<FlashSale, IFlashSale> | null = null;
   let partners: Paginate<Partner, IPartner> | null = null;
   let banners: IBanner[] | null = null;

   const [temp1, temp2, temp3, temp4, temp5, temp6, temp7, temp8, commonProps, temp10] = await Promise.all([
      Keyword.getKeywordsByType([
         // EType.color, EType.interiorStyle,
         EType.space,
      ]),
      // HomePage.listHomePage(cookiesToken),
      HomePage.actualWorks(),
      HomePage.designLibraries(),
      Product.paginate({ userIp, limit: 6, groupIds: "san-pham-ban-chay", sort: "desc", cookiesToken }),
      Product.paginate({ userIp, limit: 6, groupIds: "san-pham-de-xuat", sort: "desc", cookiesToken }),
      Brand.paginate({}),
      FlashSale.paginate({ cookiesToken, page: 1, limit: 6 }),
      Partner.getPartnerPaginate({ page: 1, limit: 1000 }),
      sharedServerSideLogic(ctx),
      Banner.getBanners({
         sections: [EBannerSection.homepage, EBannerSection.homepageright],
         devicetype: isMobile ? EBannerDevice.mobileweb : EBannerDevice.web,
      }),
   ]);

   if (!(temp1 instanceof ModelError)) {
      keywordGroupsByType = JSON.parse(JSON.stringify(temp1));
   }
   if (!(temp2 instanceof ModelError)) {
      actualWorks = JSON.parse(JSON.stringify(temp2));
   }
   if (!(temp3 instanceof ModelError)) {
      designLibraries = JSON.parse(JSON.stringify(temp3));
   }
   if (!(temp4 instanceof ModelError)) {
      products = JSON.parse(JSON.stringify(temp4));
   }
   if (!(temp5 instanceof ModelError)) {
      productsPropose = JSON.parse(JSON.stringify(temp5));
   }
   if (!(temp6 instanceof ModelError)) {
      brands = JSON.parse(JSON.stringify(temp6));
   }
   if (!(temp7 instanceof ModelError)) {
      flashSaleWithProduct = JSON.parse(JSON.stringify(temp7));
   }
   if (!(temp8 instanceof ModelError)) {
      partners = JSON.parse(JSON.stringify(temp8));
   }
   if (!(temp10 instanceof ModelError)) {
      banners = JSON.parse(JSON.stringify(temp10));
   }

   brands?.docs?.forEach((brand) => {
      if (brand.logo) {
         delete brand.logo;
      }
   });
   const title = _metadata[ctx?.resolvedUrl]?.title ?? "";
   const description = _metadata[ctx?.resolvedUrl]?.description ?? "";
   const seo: NextSeoProps = {
      title: commonProps?.metaData?.title ? commonProps?.metaData?.title : title,
      description: commonProps?.metaData?.description ? commonProps?.metaData?.description : description,
      openGraph: {
         title: commonProps?.metaData?.title ? commonProps?.metaData?.title : title,
         description: commonProps?.metaData?.description ? commonProps?.metaData?.description : description,
         type: "website",
         images: [
            {
               url: commonProps?.metaData?.thumbnail ? commonProps?.metaData?.thumbnail : _metadata[ctx?.resolvedUrl?.split("?")[0]]?.image || "",
            },
         ],
      },
   };
   return {
      props: {
         seo,
         banners,
         keywordGroupsByType: keywordGroupsByType,
         dataHomepage: {
            actualWorks,
            designLibraries,
         },
         products: products,
         productsPropose: productsPropose,
         brands: brands?.docs,
         flashSaleWithProduct,
         partners,
      } as any,
   };
};
