import LinkOrActionAuth from "@Components/LinkOrActionAuth";
import { currency } from "utils";

export default function CoinAttention(props) {
   return (
      <div className="coin-attention">
         <img className="coin-bg" src="/assets/img/home/coin-bg.png" alt="" />
         <div className="content-absolute">
            <div className="left">
               <div className="label">{!props.auth?.user ? "Đăng kí nhận ngay" : "Số xu hiện có:"}</div>
               <div className="d-flex align-items-center">
                  <img className="coin-icon" src="/assets/img/home/coin.png" alt="" />
                  <span className="coin-value">{!props.auth?.user ? "100.000" : currency(props.auth?.points?.useablePoint)}</span>
               </div>
            </div>
            <div className="right">
               <div className="label">{!props.auth?.user ? "Tích luỹ nhiều xu hơn nữa:" : "Bạn có 3 nhiệm vụ kiếm xu"}</div>
               <LinkOrActionAuth
                  isLogin={!!props.auth?.user}
                  className="d-flex align-items-center"
                  onClick={() => {
                     props.goToEarnCoin();
                  }}
               >
                  <span className="coin-earn-link">Kiếm xu ngay</span>
                  <svg className="arrow-right" xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                     <path d="M6.5 4L10.5 8L6.5 12" stroke="#FF6347" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
               </LinkOrActionAuth>
            </div>
         </div>
      </div>
   );
}
