import Menu from "@Models/menu.model";
import MenuItem from "@Models/menuItem.model";

export function getMainCategorys(shopMenus: MenuItem[]) {
   function sortByValues(arrs, values) {
      const data = [] as any[];
      for (let i = 0; i < values.length; i++) {
         data.push(arrs.find((it) => it.info.slug === values[i]));
      }
      return data;
   }

   const keys = ["noi-that", "ghe-van-phong", "ban-lam-viec", "tu-quan-ao", "giuong", "sofa", "do-trang-tri", "do-gia-dung"];
   const shopCategories = sortByValues(Menu.findMultilItemWithSlugRecursive(shopMenus, keys), keys);
   return shopCategories as MenuItem[];
}