import React from "react";
import styles from "../_scss/home.module.scss";
import Slider from "react-slick";
import classNames from "classnames";
import { ImageView } from "@Components/image";
import Link from "@Components/Link";

export default function NewsBrand() {
   const [menus, setMenus] = React.useState([
      {
         title: "",
         icon: "/assets/img/new1-mb.png",
         iconLg: "/assets/img/new1-dk.png",
         path: "https://vnexpress.net/space-t-cung-cap-da-dang-dich-vu-trong-linh-vuc-noi-that-4810707.html",
      },
      {
         title: "",
         icon: "/assets/img/new2-mb.png",
         iconLg: "/assets/img/new2-dk.png",
         path: "https://cafef.vn/noi-that-space-t-mua-noi-that-online-va-ket-noi-nha-thau-188241031160940413.chn",
      },

      {
         title: "",
         icon: "/assets/img/new3-mb.png",
         iconLg: "/assets/img/new3-dk.png",
         path: "https://www.24h.com.vn/doanh-nghiep/noi-that-space-t-lan-gio-moi-trong-xu-huong-mua-noi-that-online-va-ket-noi-doi-tac-thi-cong-c849a1614470.html",
      },
   ]);

   return (
      <div className={styles["news-brand"]}>
         <div className={classNames(styles["title"])}>Space T có mặt trên tin tức của</div>
         <div className={styles["wslider"]}>
            <Slider
               infinite={false}
               autoplay={false}
               slidesToShow={3}
               initialSlide={0}
               dots={false}
               arrows={false}
               responsive={[
                  {
                     breakpoint: 767,
                     settings: {
                        infinite: true,
                        autoplay: true,
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        autoplaySpeed: 3000,
                        waitForAnimate: true,
                     },
                  },
               ]}
            >
               {menus.map((item) => (
                  <div key={item.iconLg} className={classNames([styles["slider-item"]])}>
                     <div className={classNames([styles["item"]])}>
                        <Link href={item.path} target="_blank">
                           <ImageView
                              src={item.iconLg}
                              alt={item?.title}
                              resolution={{
                                 sm: item.icon,
                                 md: item.iconLg,
                              }}
                           />
                        </Link>
                     </div>
                  </div>
               ))}
            </Slider>
         </div>
      </div>
   );
}
